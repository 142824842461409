<template>
  <div class="home screen margin-bottom-tab padding-top-lg">
    <div class="flex justify-between align-center margin-bottom padding-lr-sm">
      <div class="flex align-center margin-bottom">
        <van-icon size="small" name="arrow-left" />
        <div class="back text-df text-bold" @click="goBack">返回</div>
      </div>
      <!-- <van-button type="info" round size="small">导出数据</van-button> -->
      <div class="flex justify-start align-center">
        <div
          :class="
            'margin-right-sm text-df text-' +
            getStatusText(resultData.status).textColor
          "
          v-if="resultData.status == 1 || resultData.status == 2"
        >
          {{ getStatusText(resultData.status).statusText }}
        </div>
        <van-button
          v-if="resultData.status == 2"
          type="danger"
          round
          size="small"
          @click="endEvaluate"
          >结束评价</van-button
        >
        <div
          class="text-df text-green margin-right-sm"
          v-if="resultData.status == 3"
        >
          评价已完成
        </div>
        <!-- TODO 导出数据 -->
        <!-- <van-button
          v-if="resultData.status == 3"
          type="info"
          round
          size="small"
          @click="exportEvaluate"
          >导出数据</van-button
        > -->
        <div class="margin-lr flex justify-start align-center">
          <van-button
            v-if="resultData.status == 1"
            type="info"
            round
            size="small"
            @click="messageAll"
            >通知自评未完成者</van-button
          >
          <van-button
            v-if="resultData.status == 2"
            type="info"
            round
            size="small"
            @click="messageAll"
            >通知互评未完成者</van-button
          >
        </div>
        <van-button type="info" round size="small" @click="exportAllEvaluate"
          >导出所有数据</van-button
        >
        <!-- TODO 进度条 -->
      </div>
    </div>
    <div class="flex align-center justify-between padding-sm">
      <div class="title text-lg text-bold">
        {{ year }}年-{{ stageText }}考核
      </div>
      <div class="flex justify-start" v-if="resultData.status != 3">
        <van-button
          v-if="resultData.status == 1"
          type="info"
          round
          size="small"
          @click="startEvaluateByEach"
          >开始互评</van-button
        >
        <van-button
          v-if="resultData.status == 1"
          type="info"
          round
          size="small"
          @click="startEvaluateByEach"
          >开始互评</van-button
        >
        <div class="flex text-df text-blue justify-start">
          {{ resultData.done }} / {{ resultData.sum }}
        </div>
        <!-- <div class="wid">
          <van-progress :percentage="70" stroke-width="8" />
        </div> -->
      </div>
      <!-- <a class="text-df text-blue" :href="file.url">
        {{ file.name }}
      </a> -->
    </div>
    <!-- <van-button type="info" size="normal" @click="test">testAdmin</van-button> -->
    <!-- accordion -->
    <van-collapse v-model="activeNames">
      <van-collapse-item
        :name="item.dept_id"
        v-for="(item, index) in departments"
        :key="index"
      >
        <template #title>
          <div class="flex align-center">
            <div class="point bg-blue margin-right-xs"></div>
            <div class="title text-df margin-right-sm">
              {{ item.dept_name }}
            </div>
            <div class="text-df">{{ item.members.length }}人</div>
          </div>
        </template>
        <van-cell-group>
          <van-cell
            is-link
            :to="'/person?userid=' + iitem.userid"
            v-for="(iitem, iindex) in item.members"
            :key="'user' + iindex"
          >
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <div class="flex justify-start align-center">
                <div
                  v-if="iitem.rank == 1"
                  class="rank bg-green margin-right-sm"
                ></div>
                <div
                  v-if="iitem.rank == 2"
                  class="rank bg-yellow margin-right-sm"
                ></div>
                <div
                  v-if="iitem.rank == 3"
                  class="rank bg-red margin-right-sm"
                ></div>
                <van-tag plain type="primary margin-right-sm">{{
                  iitem.dept_name
                }}</van-tag>
                <span class="custom-title margin-right-sm">{{
                  iitem.name
                }}</span>
                <div
                  class="text-df margin-right-sm"
                  v-if="iitem.final_score != null"
                >
                  {{ iitem.final_score }}分 - {{ iitem.score_text }}
                </div>
                <div
                  class="text-red"
                  v-if="resultData.status == 2 && iitem.hr_score == null"
                >
                  人事未评分
                </div>
              </div>
            </template>
            <template #default>
              <div :class="'text-' + getStatusText(iitem.status).textColor">
                {{
                  getStatusText(
                    iitem.status,
                    iitem.evaluate_self,
                    resultData.status
                  ).statusText
                }}
              </div>
            </template>
          </van-cell>
        </van-cell-group>
      </van-collapse-item>
    </van-collapse>
    <van-tabbar v-model="tab_active" route>
      <van-tabbar-item icon="bars" replace to="/result">结果</van-tabbar-item>
      <van-tabbar-item icon="setting" replace to="/settings"
        >维度</van-tabbar-item
      >
      <van-tabbar-item icon="user" replace to="/user">人员</van-tabbar-item>
    </van-tabbar>
    <!-- <div class="bottom-wrap">
      <div class="bottom"></div>
    </div> -->

    <van-dialog
      @confirm="onConfirm"
      @cancel="onCancel"
      v-model="dialog_show"
      title="消息通知"
      show-cancel-button
    >
      <van-form>
        <div class="flex flex-direction align-center margin-top padding">
          <van-field
            class="border"
            v-model="message"
            rows="2"
            autosize
            type="textarea"
            maxlength="100"
            placeholder="请输入评价理由"
            show-word-limit
          />
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import {
  test,
  getUserScore,
  startEvaluateAction,
  getResultSettingsAction,
  startEvaluateByEachAction,
  endEvaluateAction,
  exportEvaluateAction,
  messageEvaluateSelfAction,
  messageEvaluateEachAction,
  exportAllEvaluateAction,
} from "../api/evaluation.js";
import { getDepartmentsAction } from "../api/getCompanyInfo.js";
import api from "../api/api";
import Vue from "vue";
import {
  Tab,
  Tabs,
  Divider,
  Cell,
  CellGroup,
  Button,
  Tag,
  Collapse,
  CollapseItem,
  Icon,
  Dialog,
  Progress,
} from "vant";
// import Button from "../components/basic-components/button/index.vue";
// import DropdownMenu from "../components/basic-components/dropdownMenu/index.vue";
// import Popover from "../components/basic-components/popover/index.vue";
// import BizCalendarChooseDateTime from "../components/jsapi/biz.calendar.chooseDateTime/index.vue";
// import BizContactComplexPicker from "../components/jsapi/biz.contact.complexPicker/index.vue";

Vue.use(CellGroup);
Vue.use(Cell);
Vue.use(Divider);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Button);
Vue.use(Tag);
Vue.use(Divider);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Progress);
export default {
  name: "Home",
  components: {
    // Button,
    // DropdownMenu,
    // Popover,
    // BizCalendarChooseDateTime,
    // BizContactComplexPicker,
  },
  methods: {
    exportAllEvaluate() {
      let result_id = this.result_id;
      let that = this;
      Dialog.confirm({
        title: "提示",
        message: "确定要导出所有数据?",
      })
        .then(() => {
          exportAllEvaluateAction({
            result_id: result_id,
          }).then((res) => {
            let errno = res.errno;
            window.open(this.root + res.data, "_blank");
            if (errno == 0) {
              that.$message({
                type: "success",
                message: "导出成功",
              });
            } else {
              that.$message({
                type: "error",
                message: res.errmsg,
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onCancel() {},
    messageAll() {
      let that = this;
      let status = that.resultData.status;
      let message = "";
      if (status == 1) {
        message =
          "由于程序设计原因，全员自评完成后才能开启互评。亲爱的小伙伴，请你关注时效，加油哦！";
      } else {
        message = "HI 小伙伴们还在等着你给他们的评价呢 截止今天下班前哦！";
      }
      this.message = message;
      this.dialog_show = true;
    },
    onConfirm() {
      let that = this;
      let result_id = that.result_id;
      let message = this.message;
      let status = that.resultData.status;
      if (status == 1) {
        messageEvaluateSelfAction({
          message: message,
          result_id: result_id,
        }).then((res) => {
          let errno = res.errno;
          if (errno == 0) {
            that.$message({
              type: "success",
              message: "通知成功",
            });
          } else {
            that.$message({
              type: "error",
              message: res.errmsg,
            });
          }
          that.dialog_show = false;
        });
      } else {
        messageEvaluateEachAction({
          message: message,
          result_id: result_id,
        }).then((res) => {
          let errno = res.errno;
          if (errno == 0) {
            that.$message({
              type: "success",
              message: "通知成功",
            });
          } else {
            that.$message({
              type: "error",
              message: res.errmsg,
            });
          }
          that.dialog_show = false;
        });
      }
    },
    close() {
      this.show = false;
      this.show1 = false;
    },
    goPerson() {
      this.$router.push({ name: "Person" });
    },
    test() {
      test({}).then((res) => {
        console.log(res);
      });
    },
    endEvaluate() {
      let that = this;
      let result_id = that.result_id;
      let resultData = that.resultData;
      let done = resultData.done;
      let sum = resultData.sum;
      // TODO 等会打开
      if (done != sum) {
        that.$message({
          type: "error",
          message:
            "还有同事未完成互评,如果该同事不需要参与评价,请在人员那里将其关闭",
        });
        return false;
      }
      Dialog.confirm({
        title: "提示",
        message: "确定要结束评价,并开始计算分数?",
      })
        .then(() => {
          endEvaluateAction({ result_id: result_id }).then((res) => {
            let errno = res.errno;
            if (errno == 0) {
              that.$message({
                type: "success",
                message: "本期评价已成功完成",
              });
              that.getDepartments();
              that.getResultSettings();
            } else {
              that.$message({
                type: "error",
                message: res.errmsg,
              });
            }
          });
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    startEvaluate() {
      let that = this;
      let result_id = that.result_id;
      Dialog.confirm({
        title: "提示",
        message: "确定要进入自我评价阶段?",
      })
        .then(() => {
          startEvaluateAction({ result_id: result_id, status: 1 }).then(
            (res) => {
              let errno = res.errno;
              if (errno == 0) {
                that.$message({
                  type: "success",
                  message: "更新成功",
                });
                that.getResultSettings();
              } else {
                that.$message({
                  type: "error",
                  message: res.errmsg,
                });
              }
            }
          );
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    startEvaluateByEach() {
      let that = this;
      let result_id = that.result_id;
      let resultData = that.resultData;
      let done = resultData.done;
      let sum = resultData.sum;
      if (done != sum) {
        that.$message({
          type: "error",
          message:
            "还有同事未完成自我评价,如果该同事不需要参与评价,请在人员那里将其关闭",
        });
        return false;
      }
      Dialog.confirm({
        title: "提示",
        message: "确定要进入工作互评阶段?",
      })
        .then(() => {
          startEvaluateByEachAction({ result_id: result_id }).then((res) => {
            let errno = res.errno;
            if (errno == 0) {
              that.$message({
                type: "success",
                message: "更新成功",
              });
              that.getResultSettings();
            } else {
              that.$message({
                type: "error",
                message: res.errmsg,
              });
            }
          });
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    goBack() {
      this.$router.push("/");
    },
    getStatusText(status, self, rStatus) {
      let statusText = "";
      let textColor = "";
      if (status == 0) {
        statusText = "未开始";
        textColor = "gray";
      }

      if (status == 1) {
        statusText = "自评阶段";
        textColor = "red";
      }
      if (status == 2) {
        statusText = "互评阶段";
        textColor = "red";
      }
      if (status == 3) {
        statusText = "已完成";
        textColor = "green";
      }
      if (self == 0 && rStatus == 1) {
        statusText = "无需自评";
        textColor = "gray";
      }
      return {
        statusText: statusText,
        textColor: textColor,
      };
    },
    getDepartments() {
      let that = this;
      getDepartmentsAction().then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          that.departmentList = data;
          that.getUserScore();
          let activeNames = [];
          data.map((item) => {
            activeNames.push(item.dept_id);
          });
          that.activeNames = activeNames;
        } else {
          this.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    getUserScore() {
      let that = this;
      let result_id = that.result_id;
      getUserScore({ result_id: result_id }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          let departments = that.departmentList;
          let members = data;
          const departmentMap = new Map(
            departments.map((dept) => [dept.dept_id, { ...dept, members: [] }])
          );
          // 将成员数据添加到对应的部门对象的members数组中
          members.forEach((member) => {
            if (departmentMap.has(member.top_dept_id)) {
              departmentMap.get(member.top_dept_id).members.push({
                ...member,
              });
            }
          });
          const result = Array.from(departmentMap.values());
          that.departments = result;
        } else {
          this.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    getResultSettings() {
      let result_id = this.result_id;
      getResultSettingsAction({
        result_id: result_id,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          this.resultData = data;
        }
      });
    },
    exportEvaluate() {
      let result_id = this.result_id;
      exportEvaluateAction({
        result_id: result_id,
      }).then((res) => {
        let errno = res.errno;
        // let data = res.data;
        if (errno == 0) {
          // this.status = data.status;
        }
      });
    },
  },
  mounted() {
    let result_id = localStorage.getItem("result_id");
    this.year = localStorage.getItem("year");
    let stage = localStorage.getItem("stage");
    this.stageText = stage == 0 ? "年中" : "年终";
    this.result_id = result_id;
    this.getDepartments();
    this.getResultSettings();
  },
  data() {
    return {
      file: {
        name: "20240629.xlsx",
        url: "https://finance.nankai.edu.cn/_upload/article/files/3e/12/e434d58a49f2ba298f6b0ffc6829/f2f629e9-ac51-4a71-a96b-6a436f57cc5c.pdf",
      },
      year: 0,
      activeNames: [],
      departments: [],
      departmentList: [],
      stageText: "",
      value: 3,
      tab_active: 0,
      active: 0,
      resultData: {},
      dialog_show: false,
      message: "",
      root: api.root,
    };
  },
};
</script>

<style scoped lang="scss">
.wid {
  width: 130px;
}
.back {
  cursor: pointer;
}
.rank {
  width: 8px;
  height: 16px;
}

.score-width {
  width: 20px;
}
.screen {
  max-width: 600px;
  margin: auto;
}
.bottom-wrap {
  max-width: 600px;
  height: 50px;
  position: fixed;
  bottom: 0;
  .bottom {
    max-width: 600px;
    margin: auto;
    position: relative;
  }
}
.border {
  margin-top: 8px;
  border: 1px solid #d4d4d4;
}
::v-deep .van-cell__title {
  display: flex;
  justify-content: flex-start;
}
::v-deep .van-collapse-item__content {
  padding: 0;
}
// ::v-deep .van-cell__value {
//   max-width: 40px !important;
// }
.add {
  ::v-deep .van-cell {
    padding-top: 0;
  }
}
</style>